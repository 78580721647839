@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap);
/* 

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Black.otf");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-BlackItalic.otf");
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Bold.otf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-BoldItalic.otf");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Heavy.otf");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-HeavyItalic.otf");
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Light.otf");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-LightItalic.otf");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Medium.otf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-MediumItalic.otf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Regular.otf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-RegularItalic.otf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Semibold.otf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-SemiboldItalic.otf");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Thin.otf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-ThinItalic.otf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Ultralight.otf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-UltralightItalic.otf");
  font-style: italic;
  font-weight: 100;
} */

body {
  margin: 0;
  /* font-family: "sf-pro-display", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* .main {
  display: flex;
  flex-direction: column;
}

* .container {
  width: 1200px;
  margin: auto;
  margin-top: 46px;
}

* .topBar {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

* .tapLogo {
  width: 233.69px;
  height: 50px;
  margin-left: 20px;
}

* .menu {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

* .ourStoryText {
  margin-right: 44px;
  font-size: 14px;
  font-weight: 600;
  font-family: "sf-pro-display", sans-serif;
  color: #4f4f4f;
  cursor: pointer;
}

* .careersText {
  margin-right: 44px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  color: #4f4f4f;
  cursor: pointer;
}

* .contactUsText {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  color: #4f4f4f;
  cursor: pointer;
}

* .weMake {
  width: 100%;
  height: 450px;
  position: relative;
  margin-top: 42px;
  display: flex;
  border-radius: 50px;
  overflow: hidden;
  background: #fff;
}

* .weMakeText {
  z-index: 20;
  font-size: 28px;
  font-weight: 900;
  margin-top: 130px;
  margin-left: 89px;
  width: 338px;
  height: 108px;
  background: linear-gradient(251.16deg, #1e5dff 34.99%, #a259ff 82.94%);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
}

* .circle {
  width: 550px;
  height: 550px;
  background: #18d7ff;
  background: linear-gradient(328deg, #18d7ff 0%, #2260da 100%);
  position: absolute;
  right: 50px;
  bottom: -150px;
  border-radius: 1000px;
  z-index: 1;
  filter: blur(40px);
}

* .decor1 {
  width: 400px;
  height: 400px;
  background: #ff049824;
  position: absolute;
  border-radius: 1000px;
  bottom: -200px;
  left: 0px;
  filter: blur(100px);
}

* .decor2 {
  width: 700px;
  height: 700px;
  background: #044fff24;
  position: absolute;
  border-radius: 1000px;
  top: -200px;
  left: 30%;
  filter: blur(100px);
}

* .keyVisual {
  position: absolute;
  width: 528px;
  height: 572px;
  right: 189px;
  top: 64px;
  z-index: 10;
}

* .gradientBackground {
  position: absolute;
  width: 1217.35px;
  height: 1007.07px;
  right: 0;
  top: 0;
  margin-top: -200px;
  margin-right: -320px;
  opacity: 0.9;
}

* .ourStory {
  width: 100%;
  height: 450px;
  margin-top: 42px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

* .halfwidth {
  width: 50%;
}

* .block-gray {
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.02);
  height: 100%;
  position: relative;
}

* .storyImgCarry {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  background: #222;
  color: #39acff;
}

* .designImgCarry {
  margin-left: 20px;
  right: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

* .design {
  width: 491.46px;
  height: 491.46px;
  margin-left: -315px;
  transform: translateY(285px);
  position: absolute;
}

* .design2 {
  width: 530px;
  transform: translate(-3px, -151px);
  position: absolute;
}

* .story {
  width: 579.5px;
  position: absolute;
}

* .storyText {
  margin: 70px 0;
}

* .ourTextTittle {
  font-size: 24px;
  font-weight: bold;
  font-family: "sf-pro-display", sans-serif;
  margin: 0 50px;
}

* .ourTextContent {
  margin: 0 50px;
  color: #eee;
  font-weight: 500;
  font-family: "sf-pro-display", sans-serif;
  font-size: 13pt;
  font-weight: 400;
  line-height: 18pt;
}

* .designText {
  width: 300px;
  text-align: right;
  font-weight: bold;
  font-family: "sf-pro-display", sans-serif;
  font-size: 22px;
  position: absolute;
  bottom: 45px;
  right: 44px;
}

* .weBuildTest {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: space-between;
  margin-top: 42px;
  flex-wrap: wrap;
}

* .weBuild {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

* .weTest {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

* .build {
  width: 576.5px;
  height: 990px;
  transform: translate(20px, 70px);
  position: absolute;
}

* .test {
  width: 644.18px;
  height: 644.18px;
  left: -241.5px;
  top: 45.82px;
  position: absolute;
}

* .buildText {
  width: 300px;
  font-weight: bold;
  font-family: "sf-pro-display", sans-serif;
  font-size: 22px;
  position: absolute;
  top: 44px;
  left: 44px;
}

* .testText {
  width: 300px;
  text-align: right;
  font-weight: bold;
  font-family: "sf-pro-display", sans-serif;
  font-size: 22px;
  position: absolute;
  bottom: 44px;
  right: 44px;
}

* .careers {
  width: 100%;
  margin-top: 42px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
}

* .careers .darken {
  background: #7f43ff;
  background: linear-gradient(207deg, #ae43ff 0%, #14389e 100%);
  opacity: 0.9;
  position: absolute;
  width: 100%;
  height: 100%;
}

* .careersTexts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 39px;
  position: relative;
}

* .careersTitle {
  font-size: 24px;
  font-weight: bold;
  font-family: "sf-pro-display", sans-serif;
  margin-bottom: 7px;
  text-align: center;
  color: #fff;
}

* .careersContent {
  max-width: 500px;
  margin: 0 20px;
  position: relative;
  color: #fff;
  font-weight: 500;
  font-family: "sf-pro-display", sans-serif;
  text-align: center;
  font-size: 14pt;
}

* .jobList {
  margin: auto;
  margin-top: 39px;
  margin-bottom: 16px;
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 2;
}

* .jobCard {
  width: 190px;
  height: 320px;
  border-radius: 15px;
  background: #fff;
  margin: 0 10px 38px;
  overflow: hidden;
}

* .sortware {
  height: 110px;
  background: #007dff;
  margin-bottom: 16px;
  display: flex;
  overflow: hidden;
  position: relative;
  padding: 10px;
}

* .sortware .light1 {
  width: 200px;
  height: 200px;
  left: -120px;
  top: -70px;
  position: absolute;
  background: #2cecff;
  border-radius: 1000px;
  filter: blur(50px);
  z-index: 3;
}

* .sortware .light2 {
  width: 200px;
  height: 200px;
  right: -100px;
  bottom: -100px;
  position: absolute;
  background: #0025dc;
  border-radius: 1000px;
  filter: blur(50px);
}

* .sortwareName {
  font-style: normal;
  font-family: "sf-pro-display", sans-serif;
  font-weight: 700;
  font-size: 24pt;
  margin: auto;
  color: #ffffff;
  text-align: center;
  z-index: 2;
}

* .experience {
  font-size: 18px;
  font-weight: 700;
  font-family: "sf-pro-display", sans-serif;
  text-align: center;
  color: #1869ff;
  margin-bottom: 6px;
}

* .experience .upper {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

* .experience .lower {
  display: block;
  height: 40px;
}

* .openPosition {
  font-family: "sf-pro-display", sans-serif;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 16px;
  padding: 5px 0;
}

* .salary {
  display: flex;
  justify-content: center;
}

* .salaryClick {
  width: 158px;
  height: 42px;
  background-color: #1869ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-weight: bold;
  font-size: 14px;
  font-family: "sf-pro-display", sans-serif;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

* .footer {
  width: 100%;
  height: 242px;
  background: #000000;
  position: relative;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

* .footerAllItems {
  display: flex;
}

* .footerTittle {
  margin-top: 28.3px;
  font-weight: 400;
  font-size: 23.0922px;
  line-height: 28px;
  font-family: "sf-pro-display", sans-serif;
  margin-bottom: 10px;
}

* .footerContent {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: "sf-pro-display", sans-serif;
  margin-top: 46px;
}

* .divider {
  font-size: 12px;
}

* .terms {
  color: #ffffff;
}

* .policy {
  color: #ffffff;
}

* .ourStoryText:hover,
* .careersText:hover,
* .contactUsText:hover,
* .terms:hover,
* .policy:hover,
* .contact:hover {
  opacity: 0.7;
  transition: 0.3s;
}

* .salaryClick:hover {
  background-color: #117df8;
  transition: 0.3s;
}

* .menuMobile {
  display: none;
}

* .menuButton {
  position: fixed;
  z-index: 1000;
  top: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
}

* .menuButton .menuIcon {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 10px;
  background: #0000000a;
}

* .menuButton .menuIcon .line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  transition: 0.3s;
}

* .menuButton .menuIcon .line1 {
  transform: translateX(-10px) translateY(-4px);
}

* .menuButton .menuIcon .line2 {
  transform: translateX(-10px) translateY(4px);
}

* .menuBackground {
  position: fixed;
  z-index: 900;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 0%;
  background: rgba(255, 255, 255, 0.95);
  transition: 0.4s 0.2s cubic-bezier(0.19, 0.2, 0.17, 0.98);
}

* .menuList {
  position: fixed;
  z-index: 901;
  top: -100%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.1s 0.4s;
}

* .menuList * {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #333;
  font-weight: 400;
  transform: translateY(-10px);
  transition: 0.4s;
  opacity: 0;
}

* .menuMobile.active .menuIcon .line1 {
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

* .menuMobile.active .menuIcon .line2 {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

* .menuMobile.active .menuBackground {
  height: 100%;
  transition-delay: 0s;
}

* .menuMobile.active .menuList {
  top: 50%;
  transition-delay: 0s;
}

* .menuMobile.active .menuList * {
  transform: translateY(0);
  opacity: 1;
}

* .menuMobile.active .menuList *:nth-child(1) {
  transition-delay: 0.3s;
}

* .menuMobile.active .menuList *:nth-child(2) {
  transition-delay: 0.4s;
}

* .menuMobile.active .menuList *:nth-child(3) {
  transition-delay: 0.5s;
}

@media screen and (max-width: 1300px) {
  .container {
    width: 95vw;
  }
  .jobList {
    width: calc(500vw / 6);
  }
}

@media screen and (max-width: 850px) {
  .menu {
    display: none;
  }
  .menuMobile {
    display: block;
  }
  .container {
    width: 100%;
  }
  .block-gray {
    margin-left: 20px;
    margin-right: 20px;
  }
  .topBar {
    position: fixed;
    top: 0;
    height: 80px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(15px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  .halfwidth {
    width: 100%;
  }
  .ourStoryText, .careersText, .contactUsText {
    font-size: 24px;
    font-weight: 400;
    font-family: "sf-pro-display", sans-serif;
    color: #000000;
    cursor: pointer;
    margin: auto;
    margin-bottom: 38px;
  }
  .careersText {
    text-align: center;
  }
  .contactUsText {
    text-align: center;
    margin: auto;
    margin-bottom: auto;
  }
  .tapLogo {
    position: absolute;
    width: 135px;
    height: 28.88px;
    left: 27px;
    margin-left: 0;
    top: 26px;
  }
  .weMake {
    padding-top: 20px;
    width: 100%;
    height: 560px;
    border-radius: 2px;
    background-color: #ffffff;
    margin-top: 0;
  }
  .weMakeText {
    margin-top: 45px;
    margin-left: 32px;
    height: 400px;
  }
  .gradientBackground {
    width: 900px;
    height: 900px;
    margin-right: -350px;
    margin-top: 50px;
  }
  .keyVisual {
    width: 503px;
    height: 545px;
    right: 0;
    top: auto;
    bottom: -150px;
    margin-right: 0;
  }
  .circle {
    right: -50px;
  }
  .ourStory {
    border-radius: 0;
    justify-content: center;
    margin-top: 52px;
    height: auto;
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  .storyImgCarry {
    border-radius: 30px;
    height: auto;
    margin-top: 32px;
  }
  .storyText {
    height: auto;
  }
  .story {
    display: none;
    height: 0;
    top: 0;
  }
  .ourTextContent {
    height: auto;
  }
  .designImgCarry {
    height: 475px;
    margin-top: 32px;
  }
  .weBuildTest {
    justify-content: center;
    height: unset;
    margin-top: 32px;
  }
  .weBuild {
    margin-bottom: 32px;
    height: 475px;
  }
  .weTest {
    height: 475px;
  }
  .build {
    transform: translate(30px, 90px);
  }
  .footer {
    position: relative;
    height: 222px;
  }
  .footerAllItems {
    flex-direction: column;
    width: auto;
  }
  .footerTittle {
    font-size: 23.0922px;
    position: absolute;
    bottom: 50px;
    left: 37px;
  }
  .footerContent {
    font-size: 15px;
    position: absolute;
    bottom: 170px;
    left: 21px;
  }
  .divider {
    display: none;
  }
  .careers {
    margin: auto;
    border-radius: 0;
    margin-top: 32px;
    margin-bottom: 0;
  }
  .jobCard {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .design {
    margin-left: -315px;
  }
  .design2 {
    transform: translate(-50px, -101px);
  }
}
/*# sourceMappingURL=MainScene.css.map */
* .careersTab {
  background-color: #f4f4f4;
  overflow: hidden;
}

* body {
  background-color: #f4f4f4;
}

* .careersTabTop {
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 1000;
  background: rgba(244, 244, 244, 0.7);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(15px);
}

* .careersTabTopCarry {
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

* .backTab, * .backJob {
  display: flex;
  height: 40px;
  background: #fff;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #3292ff;
  font-weight: 700;
  font-size: 14px;
  font-family: "sf-pro-display", sans-serif;
  margin-top: 20px;
  position: absolute;
  padding: 0 18px;
}

* .careersTabText {
  font-size: 24px;
  font-weight: 600;
  height: 50px;
  font-family: sans-serif;
  color: #000000;
  cursor: pointer;
  margin: auto;
  text-align: center;
  cursor: default;
  position: absolute;
  right: 0;
  top: 25px;
}

* .careersTabBody {
  background-color: #f4f4f4;
  margin: 100px 0;
}

* .careersTabBodyCarry {
  display: flex;
  margin: 0 20px;
}

* .ctJobsTab {
  width: 360px;
  margin-right: 30px;
  margin-bottom: 40px;
}

* .ctJobCard, * .ctJDTittle, * .ctJDContent {
  width: 360px;
  min-height: 174px;
  background: #ffffff;
  border-radius: 14px;
  margin-top: 20px;
  box-sizing: border-box;
  border-color: #fff;
  border-width: 2px 2px 2px 15px;
  border-style: solid;
  transition: all 0.1s ease-out;
  display: block;
}

* .ctJobCardFocus {
  border-color: #3292ff;
}

* .ctJCTitle {
  height: 70px;
}

* .ctJCName, * .ctJDName {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  font-family: "sf-pro-display", sans-serif;
  margin-top: 24px;
  margin-left: 19px;
}

* .openpos {
  background: #3292ff;
  color: #fff;
  display: inline-block;
  padding: 3px 10px;
  border-radius: 1000px;
  margin-left: 5px;
  font-weight: 400;
}

* .ctJobDetail_highRe .openpos {
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 600;
  float: left;
  margin-left: 10px;
}

* .jobName {
  float: left;
  line-height: 32px;
}

* .ctJDTittle:not(.ctJDContent) .rowStick, * .ctJDContent:not(.ctJDContent) .rowStick {
  margin-top: 5px;
}

* .ctJCSalary, * .ctJDSalary {
  margin-top: 8px;
  margin-left: 19px;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  font-family: "sf-pro-display", sans-serif;
  color: #00c788;
}

* .requires {
  margin: 0 10px 14px;
  overflow: hidden;
}

* .requireCarry {
  float: left;
  height: 26px;
  box-sizing: border-box;
  overflow: hidden;
  background: rgba(50, 146, 255, 0.1);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #3292ff;
  font-weight: 500;
  font-size: 14px;
  font-family: "sf-pro-display", sans-serif;
  margin-left: 8px;
  margin-bottom: 10px;
  padding: 4.5px 16px;
}

* .requiresLab {
  margin-top: 18px;
  margin-left: 0;
}

* .ctJobDetail_highRe {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

* .ctContactInfo {
  width: 329px;
  height: 228px;
  background: #ffffff;
  border-radius: 14px;
  margin-left: 30px;
}

* .ctContactTittle {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  font-family: "sf-pro-display", sans-serif;
  color: #000000;
  margin-top: 24px;
  margin-left: 26px;
}

* .ctContactContent {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  font-family: "sf-pro-display", sans-serif;
  color: rgba(0, 0, 0, 0.4);
  margin: 24px;
  padding-left: 20px;
}

* .ctJobDetail {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
}

* .ctJDTittle, * .ctJDContent {
  background-color: #ffffff;
  margin-top: 0;
  width: auto;
  min-height: 0;
  border-radius: 3px;
  padding-bottom: 10px;
  padding-left: 20px;
}

* .ctJDName {
  display: block;
  font-size: 24px;
  margin: 24px 32px 20px 0;
  height: auto;
  overflow: hidden;
}

* .ctJDSalary {
  margin-left: 12px;
  font-size: 16px;
}

* .rowStick {
  width: 4px;
  height: 20px;
  border-radius: 2px;
  background-color: #3292ff;
  margin-right: 10px;
  float: left;
}

* .ctJDContent {
  margin-top: 6px;
  margin-bottom: 0;
}

* .ctJDJTTitle {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 29px;
  font-weight: 400;
  font-size: 12pt;
  line-height: 140%;
  font-family: "sf-pro-display", sans-serif;
  color: rgba(0, 0, 0, 0.6);
}

* .ctJDContent.forApply {
  border: 0;
  padding: 0 45px;
  display: block;
  overflow: hidden;
}

* .apply {
  height: 53px;
  background-color: #3292ff;
  border-radius: 14px;
  margin: 15px auto;
  text-align: center;
  line-height: 53px;
  font-weight: bold;
  font-size: 15px;
  font-family: "sf-pro-display", sans-serif;
  color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;
}

* .forApply .small {
  text-align: center;
  margin-top: 25px;
  font-size: 12pt;
  font-weight: 400;
  color: #555;
}

* .forApply .small.small1 {
  font-size: 11pt;
  margin-top: 0;
  margin-bottom: 25px;
  color: #0091ff;
}

* .ctJDTittle:not(.ctJDContent), * .ctJDContent:not(.ctJDContent) {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

* li.ctJDJTTitle {
  margin-left: 16px;
}

* .careerFooter {
  position: relative;
  width: 100%;
}

* .careerFooter .careerFooterContent {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0 70px;
  margin: 0 20px;
  color: #666;
  font-size: 10.5pt;
}

* .careersTabCenter {
  position: relative;
  margin: 0 120px;
  height: 100%;
}

* .logo {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  max-width: 500px;
  top: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(-50%);
}

@media screen and (max-width: 1500px) {
  .ctJobDetail_highRe {
    flex-direction: column;
    align-items: center;
    margin-top: 22px;
  }
  .ctJobDetail {
    display: block;
    width: 100%;
    top: 0;
  }
  .ctContactInfo {
    width: 100%;
    margin-top: 6px;
    margin-left: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .ctContactTittle, .ctContactContent {
    margin-left: 45px;
  }
}

@media screen and (max-width: 900px) {
  .careersTabBodyCarry {
    display: block;
    position: relative;
  }
  .ctJobDetail_highRe {
    position: relative;
    margin-top: 0;
    width: auto;
    display: none;
  }
  .ctJobDetail_highRe .ctJDName {
    margin-left: 5px;
  }
  .ctJobDetail_highRe .openpos {
    color: #3292ff;
    background: none;
    padding: 5px 2px;
  }
  .ctJCTitle {
    height: 90px;
  }
  .ctJobDetail {
    width: auto;
  }
  .ctContactInfo {
    width: auto;
    margin-top: 10px;
    margin-left: 0;
    overflow: hidden;
  }
  .ctContactTittle, .ctContactContent {
    margin-left: 25px;
  }
  .ctJCSalary, * .ctJDSalary {
    margin-left: 10x;
  }
  .ctJDSalary {
    margin-left: 17px;
  }
  .requires {
    width: 100%;
    margin-left: 0;
  }
  .ctJobsTab {
    margin-right: 0;
    width: 100%;
  }
  .ctJobCard, * .ctJDTittle, * .ctJDContent {
    width: auto;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
  }
  .ctJDTittle, * .ctJDContent {
    padding-left: 0;
  }
  .ctJDContent {
    padding-right: 20px;
    padding-left: 0;
  }
  .ctJDJTTitle {
    margin-left: 10px;
    margin-right: 5px;
  }
  .ctJDContent.forApply {
    padding: 0 20px;
  }
  .jobName {
    float: none;
  }
  li.ctJDJTTitle {
    margin-left: 22px;
  }
}
/*# sourceMappingURL=Careers.css.map */
.admin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.adminCarry {
  background-color: #b3dfd9;
  border-radius: 10px;
  border: solid 2px;
  width: 450px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.password {
  width: 250px;
  height: 24px;
  margin-bottom: 25px;
}

.logError {
  margin-bottom: 25px;
  color: green;
  font-size: 24px;
}

.inputFile {
  width: 350px;
  height: 50px;
  margin-left: 30px;
  margin-bottom: 40px;
  font-size: 25px;
}

.download {
  width: 180px;
  height: 40px;
  margin-right: 50px;
}

.upload {
  width: 180px;
  height: 40px;
}
/*# sourceMappingURL=Admin.css.map */
