@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');
/* 

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Black.otf");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-BlackItalic.otf");
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Bold.otf");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-BoldItalic.otf");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Heavy.otf");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-HeavyItalic.otf");
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Light.otf");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-LightItalic.otf");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Medium.otf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-MediumItalic.otf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Regular.otf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-RegularItalic.otf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Semibold.otf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-SemiboldItalic.otf");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Thin.otf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-ThinItalic.otf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-Ultralight.otf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "sf-pro-display";
  src: url("./Fonts/SF-Pro-Display-UltralightItalic.otf");
  font-style: italic;
  font-weight: 100;
} */

body {
  margin: 0;
  /* font-family: "sf-pro-display", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
